<template>
  <div>
    <b-card>
      <ValidationObserver ref="sponsor" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
          <b-row>
            <b-col md="12">
              <!-- <input-file v-model="sponsor.logo" :defaultUrl="sponsor.logoUrl" :name="$t('global.logo')" :label="$t('global.logo')" :validate="currentPage == itemPages.create ? '' : ''" :disabled="disableStatus" /> -->
              <cropper-images
                :label="$t('global.coverImage')"
                nameOfImage="image.jpg"
                @cropper-save="sponsor.logo = $event.file ? $event.file : null"
                :multi="false"
                :imageUrl="sponsor.logoUrl"
                :ratio="16 / 9"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <input-form
                v-model="sponsor.translations[0].name"
                :name="$t('sponsors.nameAr')"
                :label="$t('sponsors.nameAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <input-form
                v-model="sponsor.translations[1].name"
                :name="$t('sponsors.nameEn')"
                :label="$t('sponsors.nameEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <textarea-form
                v-model="sponsor.translations[0].banner_text"
                :name="$t('sponsors.bannerTextAr')"
                :label="$t('sponsors.bannerTextAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <textarea-form
                v-model="sponsor.translations[1].banner_text"
                :name="$t('sponsors.bannerTextEn')"
                :label="$t('sponsors.bannerTextEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="12">
              <input-form
                v-model="sponsor.url"
                :name="$t('global.url')"
                :label="$t('global.url')"
                :disabled="disableStatus"
              />
            </b-col>
          </b-row>

          <form-btns
            v-if="currentPage != itemPages.view"
            :label="submitLabel"
            :loading="loading"
          />
        </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <competitions :sponsorId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import sponsorServices from "../services/sponsors";
import Sponsor from "../models/Sponsor";
import Competitions from "../../competitions/views/Competitions.vue";
import { objectToFormData } from "../../../../Utils/helper";
import moduleItemMixin from "../../../../Mixins/moduleItemMixin";

export default {
  mixins: [moduleItemMixin],
  components: { Competitions },
  data() {
    return {
      sponsor: new Sponsor(),
    };
  },
  methods: {
    async create() {
      const formData = objectToFormData(this.sponsor);
      sponsorServices
        .create(formData)
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.$router.push({ name: "events.sponsors" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async update() {
      const formData = objectToFormData(this.sponsor);
      sponsorServices
        .update(this.id, formData)
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.$router.push({ name: "events.sponsors" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    core.index();
  },
  created() {
    if (this.id) {
      sponsorServices.findOne(this.id).then((response) => {
        this.sponsor.fillData(response.data.data);
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },
};
</script>
